import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Box, Button } from 'grommet';
import DynamicIcon from '../Icons/DynamicIcon';

import { getVariantPrice } from '../../lib/product';
import { setActiveModal } from '../../state/ui/uiSlice';
import useMobile from '../useMobile';
import useBuybarUi from './useBuybarUi';

const ProductBuyOptionsTouchUpKit = ({ variant }) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { buybarRef } = useBuybarUi();
  const handleChoose = useCallback(() => {
    dispatch(
      setActiveModal({
        fill: ['vertical', 'horizontal'],
        type: 'component',
        value: 'choose-touch-up-kit-paint',
        responsive: isMobile,
      })
    );
  });

  return (
    <Box direction="row" align="center">
      <Button
        variantId={get('shopifyId', variant)}
        label={`${getVariantPrice(variant)} Select Paint`}
        size="small"
        primary
        onClick={handleChoose}
        reverse
        icon={<DynamicIcon type="plus" />}
        ref={buybarRef}
      />
    </Box>
  );
};

ProductBuyOptionsTouchUpKit.propTypes = {
  variant: PropTypes.object.isRequired,
  maxQty: PropTypes.number,
  productTitle: PropTypes.string,
};

export default memo(ProductBuyOptionsTouchUpKit);
